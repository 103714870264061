import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;
export default [
  {
    name: 'Products',
    link: '/products',
    children: [
      {
        name: 'Point of Sale',
        link: `${CONTEXT_PATH}/products/point-of-sale`,
      },
      {
        name: 'POS Handheld',
        link: `${CONTEXT_PATH}/products/handheld`,
      },
      {
        name: 'Tablet Ordering',
        link: `${CONTEXT_PATH}/products/tablet-ordering`,
      },
      {
        name: 'QR Code Ordering',
        link: `${CONTEXT_PATH}/products/qrcode-ordering`,
      },

      {
        name: 'Kiosk',
        link: `${CONTEXT_PATH}/products/kiosk-ordering`,
      },
      // {
      //   name: '3-in-1 SmartOrdering',
      //   link: '/products/3-in-1-smart-ordering',
      // },
      {
        name: 'Restaurant Loyalty',
        link: `${CONTEXT_PATH}/products/loyalty`,
      },
      {
        name: 'Online Ordering',
        link: `${CONTEXT_PATH}/products/online-ordering`,
      },
      {
        name: 'Third party integration',
        link: `${CONTEXT_PATH}/products/thirdparty-integration`,
      },
      {
        name: 'Waitlist',
        link: `${CONTEXT_PATH}/products/waitlist`,
      },
      {
        name: 'RuTech Robot',
        link: `${CONTEXT_PATH}/products/rutech-robot`,
      },
    ],
  },
  {
    name: 'Business types',
    link: '/business-types',
    children: [
      {
        name: 'Full Service',
        link: `${CONTEXT_PATH}/business-types/full-service`,
      },
      {
        name: 'Quick Service',
        link: `${CONTEXT_PATH}/business-types/quick-service`,
      },
      {
        name: 'Hotpot',
        link: `${CONTEXT_PATH}/business-types/hot-pot`,
      },
      {
        name: 'Korean BBQ',
        link: `${CONTEXT_PATH}/business-types/korean-bbq`,
      },
      {
        name: 'Boba Tea & Dessert',
        link: `${CONTEXT_PATH}/business-types/boba-tea-dessert`,
      },

      {
        name: 'Sushi & Ramen',
        link: `${CONTEXT_PATH}/business-types/sushi-ramen`,
      },
      {
        name: 'Cafes',
        link: `${CONTEXT_PATH}/business-types/cafes`,
      },
    ],
  },
  {
    name: 'Why Chowbus POS',
    link: '/why-chowbus-pos',
  },
  {
    name: 'Learn',
    link: '/blog',
  },
  {
    name: 'Packages',
    link: '/pricing',
  },
];
