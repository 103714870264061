import { useEffect } from 'react';
import useLocale from './useLocale';
import { v4 as uuidv4 } from 'uuid';

export const HUBSPORT_FORM_FOR_PRODUCTION_SERVICE_TYPE = 'HUBSPORT_FORM_FOR_PRODUCTION_SERVICE_TYPE';
export const HUBSPORT_FORM_FOR_PAGE_BOTTOM = 'HUBSPORT_FORM_FOR_PAGE_BOTTOM';
export const HUBSPORT_FORM_FOR_DEMO_PAGE = 'HUBSPORT_FORM_FOR_DEMO_PAGE';

const HubspotFormMapping = {
  [HUBSPORT_FORM_FOR_PRODUCTION_SERVICE_TYPE]: {
    zh: 'f922b4d6-02b2-4062-9d08-c7c55834605e',
    en: 'd0c7f10d-048f-4297-9786-3370e0bf608d',
  },
  [HUBSPORT_FORM_FOR_PAGE_BOTTOM]: {
    zh: '80ba46a7-51ac-44d6-8d7a-53141d9b9cc4',
    en: '835adb40-fe8b-470f-a8cd-9e8769815058',
  },
  [HUBSPORT_FORM_FOR_DEMO_PAGE]: {
    zh: '7061f334-dc2f-4e80-8fe8-a89c0a20a139',
    en: 'ea91da2b-9610-47d2-acc2-d0531b70ef1c',
  },
};
export function useHubspotEmbedFormCreator({
  targetClassSelector,
  formType = HUBSPORT_FORM_FOR_PRODUCTION_SERVICE_TYPE,
}) {
  const locale = useLocale();

  useEffect(() => {
    if (window.hbspt) {
      let formMapping = HubspotFormMapping[formType];
      let formId = '';
      if (formMapping) {
        formId = formMapping[locale] || formMapping['en'] || '';
      }
      formId &&
        window.hbspt.forms.create({
          portalId: '47969074',
          formId,
          target: `${'.' + locale}${targetClassSelector}`,
          formInstanceId: uuidv4(),
        });
    }
  }, [locale]);
}
