const SectionContent = {
  topSection: {
    en: {
      slogan: 'A POS System That Delivers Real Impact',
      sloganSubTitle: '',
      whyLoveChowbus: `Why
      Restaurants
      Love Chowbus`,
      points: [
        ['Connected.', 'Save $8,000/mo on Labor Per Location'],
        ['Comprehensive.', 'Grow Your Business 25%'],
        ['Easy-to-Use.', '24/7 Customer Support'],
      ],
      videoUrl: `chowbus_introduction_en_720.mp4`,
    },
    zh: {
      slogan: '餐馆电脑引领者：Chowbus餐馆电脑，专为亚洲餐厅定制',
      sloganSubTitle: '',
      whyLoveChowbus: `为什么
      餐饮业
      爱Chowbus`,
      points: [
        ['更高效的运营策略.', '为餐馆节省近$100k/年的人力开销'],
        ['更全面的产品方案.', '多样的产品组合帮助您解决不同痛点'],
        ['更友好的使用体验.', '简单的交互以实现无痛学习、轻松上手'],
      ],
      videoUrl: `chowbus_introduction_cn_720.mp4`,
    },
  },
  whyNotChooseChowBus: {
    en: {
      title: 'Why Restaurants Choose Chowbus',
    },
    zh: {
      title: '他们选择Chowbus餐馆电脑的原因',
    },
  },
};

export default SectionContent;

export const LogosOnBrandWall = [
  'molly_tea.webp',
  'Babbi_Babbi_logo.webp',

  'burnin_99.webp',

  'Chili_Spot.webp',
  'X_Pot_new.webp',

  'jiang_nan.webp',
  'jongrobbq.webp',
  'toppot.webp',
  'kyuramen.webp',
  'kpot.webp',

  'bakery_1908.webp',
  'machi_machi.webp',
  'Chubby_Cattle.webp',
  'Y_Space.webp',
  'malubianbian.webp',
  'Yangguofu.webp',
  'mikiya-logo.webp',
  'mochi_nut.webp',
  'Er_Hot_Pot.webp',
  'Yi_Fang_Logo.webp',
  'nan_huo_guo.webp',
  'one_zo.webp',
  /*** */
  // 'molly_tea.webp',
  /*** */
  'Fancy_Sushi_Grill.webp',
  'Zhang_liang.webp',
  'paris_banh_mi.webp',
  'Fluffy_Fluffy_Logo.webp',
  'bu_er_jia.webp',
  'cha_tang_hui.webp',
  'speedy_korean_grill.webp',
  'Hey_Yogurt.webp',
  'chao_cha.webp',
  'teamo.webp',
  'craftycrab.webp',
  'tiger_sugar.webp',
  'Izakaya_Nana.webp',
  'ji_bei_chuan.webp',
  'vivi_bubble_tea.webp',
  'xiangtianxia.webp',
  'da_long_yi.webp',
  'xiaolongkai.webp',
  'jongro_rice_hot_dog.webp',
  'dun_huang.webp',
  /*** */
  // 'molly_tea.webp',
  /*** */
  'xing_fu_tang.webp',
  'logo-whale-tea.webp',
  'nanas_green_tea.webp',
  'you_qin_ke_chuan.webp',
  'gang_guan_wu_qu.webp',
  'o_mandarin.webp',
  'RB_Tea.webp',

  'yuan_cha.webp',
  'sushi_taku.webp',

  'Shu_Da_Xia.webp',

  'mingdushengyan.webp',

  'yh_dim_sum.webp',
  'yh_seafood.webp',
  /*** */
  'molly_tea.webp',
  /*** */
  'yhs_china_bistro.webp',
  'token_ramen_tea.webp',
  'Tbaar.webp',
  'lao_sze_chuan.webp',

  'zen_dumpling.webp',
  'liu_yi_shou.webp',

  'nanshan_hotpot.webp',
  'serious_dumplings.webp',
  'letao.webp',
  'china_a_gogo.webp',
];
